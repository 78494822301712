import mongoose, { Document, Schema } from 'mongoose';

export const ENUM = {
  Enrichment: ['PicturePrompts', 'Facts'],
  Cognition: [
    'WhatsInThePicture',
    'CommonGround',
    'SoundOfLifePhoto',
    'MemoryCards',
    'Commonality',
    'HowToMake',
  ],
  Speech: ['ArticulationWord', 'ArticulationPhrase', 'ArticulationSentense'],
  Language: ['Meaning', 'WHQuestions', 'VideoPrompts'],
};

export interface Role {
  name: string;
}

export interface MongooseRole extends Role, Document {}

const RoleSchema = new Schema({
  name: { type: String, unique: true, index: true },
  enabled: { type: Boolean, index: true },
});

export const Role = mongoose.model<MongooseRole>('Role', RoleSchema) as any;
