import mongoose, { Document, Schema } from 'mongoose';
import {handleUserDataDispatch} from "./utils/dispatchUtils";

export const PURCHASE_ENUM = {
  chargingCycle: ['monthly', 'yearly', 'one-time'],
};

export interface UserProfile {
  user: any;
  purchases: any;
}

export interface MongoosePurchase extends UserProfile, Document {}

const PurchaseSchema = new Schema({
  user: { type: mongoose.Schema.Types.ObjectId, ref: 'User' },
  purchases: [
    {
      plan: { type: mongoose.Schema.Types.Mixed }, // { type: mongoose.Schema.Types.ObjectId, ref: 'Plan' },
      status: { type: String },
      charged: { type: String },
      currency: { type: String },
      cycleChargeAmount: { type: Number },
      paypalPlanId: { type: String },
      initialNextBillingTime: { type: Date },
      purchaseDate: { type: Date },
      cancellationDate: { type: Date },
      cancellationSource: { type: String },
      autoRenew: { type: Boolean, default: true },
      paypalPayerId: { type: String },
      paypalSubscriptionId: { type: String },
      subscriptionStartDate: { type: Date },
      subscriptionEndDate: { type: Date },
      planId: { type: Schema.Types.ObjectId, ref: 'Plan' },
      coupon: { type: String },
      licenses: { type: String },
      _raw: { type: String },
      createdAt: { type: mongoose.Schema.Types.Date },
    },
  ],
});

PurchaseSchema.pre('save', async function (this: Document, next): Promise<void> {
  const that = this as any; //MongoosePurchase;
  try {
    if (this.isModified('purchases')) {
      const purchasesData =  await that.populate("user").execPopulate();
      const user = purchasesData.user;
      await handleUserDataDispatch(user, that.purchases?.slice(-1)[0])
    }
  }
  catch (error) {
    console.error(error);
  }
  next();
});

export const Purchase = mongoose.model<MongoosePurchase>(
  'Purchase',
  PurchaseSchema
) as any;
