import { findActivityOne, findArticulationOne } from '@/services/api';
import { Activity, LanguagesAlongWithWebsite, Role } from '@/store/entities';
import { hasRoleInUserOrOrg, Roles } from '@packages/roles';
import { useState } from '@u3u/vue-hooks';
import { document, window } from 'global';
import { usePromise } from 'vue-composable';
import dasherize from 'dasherize';
import {
  ACTIVITY_ENUM,
  ACTIVITY_PLANNING_COLUMN_TYPE,
  ALLOWED_LANGUAGES,
  ActivityTypes,
  AUDIO_MODE,
  DROPPER_TYPE,
  STORY_SEQUENCE_ACTIVITY_CONFIG,
  RECALL_DAILY_TASKS_CONFIG,
  ASSEMBLING_WORDS,
  ANAGRAM_CONFIG,
} from '@packages/mongodb';
import moment from 'moment';
import { environment } from '@/env';

const activitySlidesPreLevels = {
  MemoryPairs: {
    1: 4,
    2: 4,
    3: 8,
    4: 8,
    5: 10,
    6: 10,
    7: 15,
    8: 15,
    9: 18,
    10: 18,
  },
};

const slidesToCheckActivities = ['MemoryPairs'];

export const isSlideToCheckActivity = (activityType) => {
  return slidesToCheckActivities.includes(activityType);
};

export const isSlideLengthNotBaseOnLevel = (activityType, slideLength, level) => {
  if (activitySlidesPreLevels[activityType][level] !== slideLength) {
    return true;
  }
  return false;
};

export async function waitForReadystate() {

  if (typeof document !== 'undefined' && document.readyState !== 'complete') {
    await new Promise<void>((resolve) => {
      const cb = () => {
        window.requestAnimationFrame(() => {
          resolve();
        });
        window.removeEventListener('load', cb);
      };
      window.addEventListener('load', cb);
    });
  }
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitForNextFrame() {
  return new Promise<void>((resolve) => {
    window.requestAnimationFrame(() => {
      resolve();
    });
  });
}

export function getUniqueTags() {
  return Array.from(
    new Set(
      Activity.all()
        .filter((activity) => activity['tags'])
        .map((activity) => activity['tags'])
        .flat()
    )
  );
}

let tags = [];
export function getUniqueTagsOnce() {
  if (tags.length === 0) {
    tags = Array.from(
      new Set(
        Activity.all()
          .filter((activity) => activity['tags'])
          .map((activity) => activity['tags'])
          .flat()
      )
    );
  }
  return tags;
}

export function getTextEditorAllowedLanguages() {
  const adminTextEditorRoleId = Role.all().find((role) => role['name'] === Roles.ADMIN_TEXT_EDITOR)['_id'];
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.roles.find((role) => role.id === adminTextEditorRoleId)?.adminTextEditorLanguages;
}

export function getUserRolesIds() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser.value?.roles.map((role) => role.id);
}

export function hasRole(roleName) {
  const roleId = Role.all().find((role) => role['name'] === roleName)?.['_id'];
  const userRolesIds = getUserRolesIds();
  return userRolesIds && userRolesIds.includes(roleId);
}

// export function isAdminUser() {
//   const roleId = Role.all().find((role) => role['name'] === Roles.ADMIN)['_id'];
//   const { user: currentUser } = useState('auth', ['user']);
//   return hasRoleInUserOrOrg(currentUser, roleId);
// }

// We are add this tempContributor for only few weeks due to issue in contributor role.

export function isTmpContributor() {
  return hasRole(Roles.TMP_CONTRIBUTOR);
}

export function isAdminUser() {
  const roleId = Role.all().find((role) => role['name'] === Roles.ADMIN)['_id'];
  const { user: currentUser } = useState('auth', ['user']);
  return hasRoleInUserOrOrg(currentUser, roleId) || isTmpContributor();
}

export function isTextEditor() {
  return hasRole(Roles.ADMIN_TEXT_EDITOR);
}

export function isContributor() {
  return hasRole(Roles.COGNISHINE_CONTRIBUTOR);
}

export function isOrgContributor() {
  return hasRole(Roles.ORG_CONTRIBUTOR);
}

export const isUserHasOrganization = (user = null) => {
  if (user) {
    return (user !== null && user?.organization !== null && typeof user?.organization === 'object') === true;
  }
  const { user: userFromState } = useState('auth', ['user']);

  return (
    (userFromState.value !== null &&
      userFromState.value?.organization !== null &&
      typeof userFromState.value?.organization === 'object') === true
  );
};

export function getUserOrganization() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.organization;
}

export function disableLang(lang) {
  if (isAdminUser() || isContributor()) return false;
  const allowedLanguages = getTextEditorAllowedLanguages();
  if (!allowedLanguages) return true;
  return !allowedLanguages.includes(lang);
}

function flatArticulationMetadata(activity) {
  const results = [
    ...activity.metadata?.blend?.map((blend) => {
      return { media: blend?.media, audio: blend?.audio };
    }),
    // .flat()
    ...activity.metadata?.syllable?.map((syllable) => {
      return { media: syllable?.media, audio: syllable?.audio };
    }),
    // .flat(),
  ];
  return results;
}

export const checkActivityPublishedInLanguage = (lang, activityPublished) => {
  const languages = (
    LanguagesAlongWithWebsite.query()
      .all()
      ?.find((language: any) => language?.languageCode === lang) as any
  )?.website.find((website) => activityPublished?.includes(website._id));
  if (languages) return true;
  return false;
};

export const getWebsiteFromLanguage = (lang) => {
  return (
    LanguagesAlongWithWebsite.query()
      .all()
      ?.find((language: any) => language?.languageCode === lang) as any
  )?.website.map((website) => {
    return website._id;
  });
};

export const getAvailableWebsite = () => {
  const availableWebsite = [];
  LanguagesAlongWithWebsite.query()
    .all()
    ?.map((lang: any) => {
      if (lang.website.length) {
        lang.website.forEach((website: any) => {
          availableWebsite.push({ ...website, lang: lang?.languageCode, locale: `${lang.languageCode} - ${website.website}` });
        });
      }
    });
  return availableWebsite;
};

export const getLanguageObjectByLanguageCode = (languageCode: string) => {
  const allLanguagesWithWebsite = LanguagesAlongWithWebsite.query().all();
  const lang = allLanguagesWithWebsite?.find((lang: any) => lang.languageCode === languageCode);
  return lang;
};

export function hasNewMedia(activity, articulationActivity = false) {
  let metadataToPrepare = [];
  if (activity) metadataToPrepare = activity.metadata.slides;
  if (articulationActivity) metadataToPrepare = flatArticulationMetadata(activity);

  for (const slide of metadataToPrepare) {
    if (slide.media) {
      for (const media of slide.media) if (media.hasMedia) return true;
    }
    if (slide.audio) {
      for (const audio of slide.audio) if (audio.hasMedia) return true;
    }
    if (slide.alternateAudio) {
      for (const audio of slide.alternateAudio) if (audio.hasMedia) return true;
    }
    if (slide.activityPlaningItems) {
      for (const planingItem of slide.activityPlaningItems) {
        if (planingItem?.media?.hasMedia) {
          return true;
        }
      }
    }
    if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
      if (slide?.wordDefItems && slide?.wordDefItems?.length) {
        for (const wordDefItem of slide?.wordDefItems) {
          for (const wordSubDefItem of wordDefItem) {
            for (const langKey of Object.keys(wordSubDefItem?.phrases || {})) {
              if (wordSubDefItem?.phrases[langKey]?.isChanged) {
                return true;
              }
            }
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.SpeechSoundPictureScenes) {
      for (const langKey of Object.keys(slide?.speechSoundPictureScenesItems?.phrases || {})) {
        for (const subItem of slide?.speechSoundPictureScenesItems?.phrases[langKey]) {
          if (subItem?.isChanged) {
            return true;
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.Judgement) {
      if (slide?.judgementItems) {
        for (const langKey of Object.keys(slide.judgementItems?.situationPhrase || {})) {
          if (slide.judgementItems?.situationPhrase[langKey]?.isChanged) {
            return true;
          }
        }

        for (const detailsItem of slide.judgementItems?.situationDetailItems) {
          for (const phrases of detailsItem?.phrases) {
            for (const langKey of Object.keys(phrases.phrase || {})) {
              if (phrases?.phrase[langKey]?.isChanged) {
                return true;
              }
            }
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.RecallDailyTasks) {
      if (slide?.generalSlidesConfigurations?.columnType === RECALL_DAILY_TASKS_CONFIG.columnType.recallDailyTasks) {
        // For sender name(audio)
        for (const langKey of Object.keys(slide?.recallDailyTasks?.sender?.phrases || {})) {
          if (slide.recallDailyTasks?.sender?.phrases[langKey]?.isChanged) {
            return true;
          }
        }

        // For sender image
        for (const media of slide?.recallDailyTasks?.sender?.media) {
          if (media?.isChanged || media?.hasMedia) {
            return true;
          }
        }

        // For Note Section
        for (const langKey of Object.keys(slide?.recallDailyTasks?.note?.phrases || {})) {
          if (slide.recallDailyTasks?.note?.phrases[langKey]?.isChanged) {
            return true;
          }
        }

        //  For Text Message Section
        for (const langKey of Object.keys(slide?.recallDailyTasks?.textMessage?.phrases || {})) {
          if (slide.recallDailyTasks?.textMessage?.phrases[langKey]?.isChanged) {
            return true;
          }
        }

        // For Voice Message Section
        for (const langKey of Object.keys(slide?.recallDailyTasks?.voiceMessage?.phrases || {})) {
          if (slide.recallDailyTasks?.voiceMessage?.phrases[langKey]?.isChanged) {
            return true;
          }
        }

        // For Phone Message Section
        for (const langKey of Object.keys(slide?.recallDailyTasks?.phoneMessage?.phrases || {})) {
          if (slide.recallDailyTasks?.phoneMessage?.phrases[langKey]?.isChanged) {
            return true;
          }
        }

        // For Question section
        for (const langKey of Object.keys(slide?.recallDailyTasks?.question?.phrases || {})) {
          if (slide?.recallDailyTasks?.question?.phrases[langKey]?.isChanged) {
            return true;
          }
        }

        // For Answers Section
        for (const items of slide?.recallDailyTasks?.answers?.items) {
          for (const langKey of Object.keys(items?.phrases || {})) {
            if (items?.phrases[langKey]?.isChanged) {
              return true;
            }
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.AssemblingWords) {
      if (slide?.generalSlidesConfigurations?.columnType === ASSEMBLING_WORDS.columnType.assemblingWords) {
        // For words phrases
        for (const langKey of Object.keys(slide?.assemblingWords?.words?.phrases || {})) {
          if (slide.assemblingWords?.words?.phrases[langKey]?.isChanged) {
            return true;
          }
        }

        // For words image
        for (const media of slide?.assemblingWords?.words?.media) {
          if (media?.isChanged || media?.hasMedia) {
            return true;
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.Anagram) {
      if (slide?.generalSlidesConfigurations?.columnType === ANAGRAM_CONFIG.columnType.anagram) {
        // For words phrases
        for (const langKey of Object.keys(slide?.anagram?.words?.phrases || {})) {
          if (slide.anagram?.words?.phrases[langKey]?.isChanged) {
            return true;
          }
        }
      }
    }
  }
  return false;
}

export function prepareUploadFiles(activity, dropperType = DROPPER_TYPE.activity) {
  const formData = new FormData();
  let metadataToPrepare = [];
  if (activity && (dropperType as any) === DROPPER_TYPE.activity) metadataToPrepare = activity.metadata.slides;
  if ((dropperType as any) === DROPPER_TYPE.articulation) metadataToPrepare = flatArticulationMetadata(activity);
  for (const slide of metadataToPrepare) {
    if (slide.media) {
      for (const media of slide.media) {
        if (media?.file != null) {
          formData.append('mediaArray', media.file, media.name);
        }
        if (media?.videoThumbnail != null) {
          formData.append('mediaArray', media.videoThumbnail, `thumbnail_${media.name}`);
        }
      }
    }
    if (slide?.audio) {
      for (const audio of slide?.audio) {
        if (audio?.file != null) {
          formData.append('mediaArray', audio.file, audio.name);
        }
      }
    }
    if (slide?.alternateAudio) {
      for (const audio of slide?.alternateAudio) {
        if (audio?.file != null) {
          formData.append('mediaArray', audio.file, audio.name);
        }
      }
    }
    if (slide.activityPlaningItems) {
      for (const planingItem of slide.activityPlaningItems) {
        if (planingItem?.media?.file != null) {
          formData.append('mediaArray', planingItem?.media?.file, planingItem?.media?.name);
        }
      }
    }
    if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
      if (slide?.wordDefItems && slide?.wordDefItems?.length) {
        for (const wordDefItem of slide?.wordDefItems) {
          for (const wordSubDefItem of wordDefItem) {
            for (const langKey of Object.keys(wordSubDefItem?.phrases || {})) {
              if (wordSubDefItem?.phrases[langKey]?.isChanged && wordSubDefItem?.phrases[langKey]?.audioString) {
                const blobFile = base64toBlob(
                  wordSubDefItem?.phrases[langKey]?.audioString,
                  wordSubDefItem?.phrases[langKey]?.audioName,
                  'audio/mpeg'
                );
                if (blobFile) {
                  formData.append('mediaArray', blobFile, wordSubDefItem?.phrases[langKey]?.audioName);
                }
              }
            }
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.SpeechSoundPictureScenes) {
      if (slide?.speechSoundPictureScenesItems) {
        for (const langKey of Object.keys(slide.speechSoundPictureScenesItems?.phrases || {})) {
          slide.speechSoundPictureScenesItems?.phrases[langKey].forEach((ele: any) => {
            if (ele?.isChanged && ele?.audioString) {
              const blobFile = base64toBlob(ele?.audioString, ele?.audioName, 'audio/mpeg');
              if (blobFile) {
                formData.append('mediaArray', blobFile, ele?.audioName);
              }
            }
          });
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.Judgement) {
      if (slide?.judgementItems) {
        for (const langKey of Object.keys(slide.judgementItems?.situationPhrase || {})) {
          if (slide.judgementItems?.situationPhrase[langKey]?.isChanged && slide.judgementItems?.situationPhrase[langKey]?.audioString) {
            const blobFile = base64toBlob(
              slide.judgementItems?.situationPhrase[langKey]?.audioString,
              slide.judgementItems?.situationPhrase[langKey]?.audioName,
              'audio/mpeg'
            );
            if (blobFile) {
              formData.append('mediaArray', blobFile, slide.judgementItems?.situationPhrase[langKey]?.audioName);
            }
          }
        }

        slide.judgementItems?.situationDetailItems.forEach((ele: any) => {
          ele.phrases.forEach((phraseEle: any) => {
            for (const langKey of Object.keys(phraseEle.phrase || {})) {
              if (phraseEle.phrase[langKey]?.isChanged && phraseEle.phrase[langKey]?.audioString) {
                const blobFile = base64toBlob(phraseEle.phrase[langKey]?.audioString, phraseEle.phrase[langKey]?.audioName, 'audio/mpeg');
                if (blobFile) {
                  formData.append('mediaArray', blobFile, phraseEle.phrase[langKey]?.audioName);
                }
              }
            }
          });
        });
      }
    }

    if (activity?.type?.name === ActivityTypes.RecallDailyTasks) {
      if (slide?.recallDailyTasks) {
        // For sender name(audio)
        processPhrases(slide?.recallDailyTasks?.sender?.phrases, formData);

        // For sender image
        for (const media of slide.recallDailyTasks.sender?.media) {
          appendMediaToFormData(media, formData);
        }

        // For Note Section
        processPhrases(slide.recallDailyTasks.note.phrases, formData);

        //  For Text Message Section
        processPhrases(slide.recallDailyTasks.textMessage.phrases, formData);

        // For Voice Message Section
        processPhrases(slide.recallDailyTasks.voiceMessage.phrases, formData);

        // For Phone Message Section
        processPhrases(slide.recallDailyTasks.phoneMessage.phrases, formData);

        // For Question section
        processPhrases(slide.recallDailyTasks.question.phrases, formData);

        // For Answers Section
        processItems(slide?.recallDailyTasks?.answers?.items, formData);
      }
    }

    if (activity?.type?.name === ActivityTypes.AssemblingWords) {
      if (slide?.assemblingWords) {
        // For words phrases
        processPhrases(slide?.assemblingWords?.words?.phrases, formData);

        // For words media
        for (const media of slide.assemblingWords.words?.media) {
          appendMediaToFormData(media, formData);
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.Anagram) {
      if (slide?.anagram) {
        // For words phrases
        processPhrases(slide?.anagram?.words?.phrases, formData);
      }
    }
  }

  return formData;
}

// INFO: Helper function to append media for prepareUploadFiles function
const appendMediaToFormData = (media, formData) => {
  if (media?.isChanged && media?.audioString) {
    const blobFile = base64toBlob(media.audioString, media.audioName, 'audio/mpeg');
    if (blobFile instanceof Blob) {
      formData.append('mediaArray', blobFile, media.audioName);
    }
  }
  if (media?.file && media?.file instanceof Blob) {
    formData.append('mediaArray', media.file, media.name);
  }
};

// INFO: Helper function to append phrases for prepareUploadFiles function
const processPhrases = (phrases, formData) => {
  for (const langKey of Object.keys(phrases || {})) {
    if (phrases[langKey]?.isChanged && phrases[langKey]?.audioString) {
      const blobFile = base64toBlob(phrases[langKey].audioString, phrases[langKey].audioName, 'audio/mpeg');
      if (blobFile instanceof Blob) {
        formData.append('mediaArray', blobFile, phrases[langKey].audioName);
      }
    }
  }
};

// INFO: Helper function to append phrases & media from list of items for prepareUploadFiles function
const processItems = (items, formData: FormData) => {
  for (const item of items) {
    processPhrases(item?.phrases, formData);
    if (item?.media) {
      for (const media of item?.media) {
        appendMediaToFormData({ ...media }, formData);
      }
    }
  }
};

export function base64toBlob(base64String: string, fileName: string, fileType: string) {
  try {
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new File([new Blob([byteArray])], fileName, { type: fileType });
  } catch (error) {
    console.error('Error decoding base64 string:', error);
    return null;
  }
}

export function deleteActivityMedia(activity, articulationActivity = false) {
  let metadataToPrepare = [];
  if (activity) metadataToPrepare = activity.metadata.slides;
  if (articulationActivity) metadataToPrepare = flatArticulationMetadata(activity);

  metadataToPrepare
    .map((slide) => slide.media)
    .flat()
    .map((media) => {
      if (media?.videoThumbnail) delete media.videoThumbnail;
      if (media?.src) delete media.src;
      if (media?.originalImageThumbnail) delete media.originalImageThumbnail;
    });
  metadataToPrepare
    .map((slide) => slide.audio)
    .flat()
    .map((audio) => {
      if (audio?.src) delete audio.src;
    });

  if (activity.type.name === ActivityTypes.ActivityPlanning) {
    metadataToPrepare
      ?.map((slide) => slide.activityPlaningItems)
      ?.flat()
      ?.map((el) => el.media)
      ?.filter((el) => el)
      ?.map((el) => {
        if (el.originalImageThumbnail) delete el.originalImageThumbnail;
      });
  }

  if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.wordDefItems) {
        slide.wordDefItems.forEach((wordDefItem: any) => {
          wordDefItem.forEach((wordDefSubItem: any) => {
            for (const langKey of Object.keys(wordDefSubItem?.phrases || {})) {
              if (wordDefSubItem?.phrases[langKey]?.audioString || wordDefSubItem?.phrases[langKey]?.audioString === '') {
                delete wordDefSubItem?.phrases[langKey]?.audioString;
              }
              if (wordDefSubItem?.phrases[langKey]?.audioName === '') {
                delete wordDefSubItem?.phrases[langKey]?.audioName;
              }
            }
          });
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.SpeechSoundPictureScenes) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.speechSoundPictureScenesItems) {
        for (const langKey of Object.keys(slide?.speechSoundPictureScenesItems?.phrases || {})) {
          slide?.speechSoundPictureScenesItems?.phrases[langKey].forEach((ele: any) => {
            if (ele?.audioString || ele?.audioString === '') {
              delete ele?.audioString;
            }
            if (ele?.audioName === '') {
              delete ele?.audioName;
            }
          });
        }
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.Judgement) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.judgementItems) {
        for (const langKey of Object.keys(slide?.judgementItems?.situationPhrase || {})) {
          if (
            slide?.judgementItems?.situationPhrase[langKey].audioString ||
            slide?.judgementItems?.situationPhrase[langKey].audioString === ''
          ) {
            delete slide?.judgementItems?.situationPhrase[langKey].audioString;
          }
          if (slide?.judgementItems?.situationPhrase[langKey].audioName === '') {
            delete slide?.judgementItems?.situationPhrase[langKey].audioName;
          }
        }

        slide.judgementItems?.situationDetailItems.forEach((ele: any) => {
          ele.phrases.forEach((phraseEle: any) => {
            for (const langKey of Object.keys(phraseEle.phrase || {})) {
              if (phraseEle.phrase[langKey]?.audioString || phraseEle.phrase[langKey]?.audioString === '') {
                delete phraseEle.phrase[langKey]?.audioString;
              }
              if (phraseEle.phrase[langKey]?.audioName === '') {
                delete phraseEle.phrase[langKey]?.audioName;
              }
            }
          });
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.RecallDailyTasks) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.recallDailyTasks) {
        // For sender name(audio)
        deletePhraseProperties(slide?.recallDailyTasks?.sender?.phrases);

        // For sender image
        deleteMediaProperties(slide.recallDailyTasks.sender?.media);

        // For Note Section
        deletePhraseProperties(slide.recallDailyTasks.note.phrases);

        //  For Text Message Section
        deletePhraseProperties(slide.recallDailyTasks.textMessage.phrases);

        // For Voice Message Section
        deletePhraseProperties(slide.recallDailyTasks.voiceMessage.phrases);

        // For Phone Message Section
        deletePhraseProperties(slide.recallDailyTasks.phoneMessage.phrases);

        // For Question section
        deletePhraseProperties(slide.recallDailyTasks.question.phrases);

        // For Answers Section
        slide?.recallDailyTasks?.answers?.items?.forEach((answerItem: any) => {
          deletePhraseProperties(answerItem?.phrases);
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.AssemblingWords) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.assemblingWords) {
        // For words phrases
        deletePhraseProperties(slide?.assemblingWords?.words?.phrases);

        // For words media
        deleteMediaProperties(slide.assemblingWords.words?.media);
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.Anagram) {
    metadataToPrepare.forEach((slide: any) => {
      if (slide && slide?.anagram) {
        // For words phrases
        deletePhraseProperties(slide?.anagram?.words?.phrases);
      }
    });
  }
}

// INFO: Helper function for delete media for deleteActivityMedia function.
const deleteMediaProperties = (mediaArray) => {
  mediaArray.forEach((media, index) => {
    if (media?.audioString || media?.audioString === '') {
      delete mediaArray[index].audioString;
    }
    if (media?.audioName === '') {
      delete mediaArray[index].audioName;
    }
    if (media?.videoThumbnail) {
      delete mediaArray[index].videoThumbnail;
    }
    if (media?.src) {
      delete mediaArray[index].src;
    }
    if (media?.originalImageThumbnail) {
      delete mediaArray[index].originalImageThumbnail;
    }
  });
};

// INFO: Helper function for delete phrases audio details for deleteActivityMedia function.
const deletePhraseProperties = (phrases) => {
  for (const langKey of Object.keys(phrases || {})) {
    if (phrases[langKey]?.audioString || phrases[langKey]?.audioString === '') {
      delete phrases[langKey].audioString;
    }
    if (phrases[langKey]?.audioName === '') {
      delete phrases[langKey].audioName;
    }
  }
};

export function removeAudioExtraDetails(activity) {
  if (activity?.type?.name === ActivityTypes.WordFromTheDefinition) {
    activity.metadata.slides.forEach((slide: any) => {
      if (slide && slide?.wordDefItems) {
        slide.wordDefItems.forEach((wordDefItem: any) => {
          wordDefItem.forEach((wordDefSubItem: any) => {
            for (const langKey of Object.keys(wordDefSubItem?.phrases || {})) {
              if (wordDefSubItem?.phrases[langKey]?.audioName === '') {
                delete wordDefSubItem?.phrases[langKey]?.audioName;
              }
              delete wordDefSubItem.phrases[langKey].audioString;
              delete wordDefSubItem.phrases[langKey].isChanged;
            }
          });
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.SpeechSoundPictureScenes) {
    activity.metadata.slides.forEach((slide: any) => {
      if (slide && slide?.speechSoundPictureScenesItems) {
        for (const langKey of Object.keys(slide?.speechSoundPictureScenesItems?.phrases || {})) {
          slide?.speechSoundPictureScenesItems?.phrases[langKey].forEach((ele: any) => {
            if (ele?.audioName === '') {
              delete ele?.audioName;
            }
            delete ele.audioString;
            delete ele.isChanged;
          });
        }
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.Judgement) {
    activity.metadata.slides.forEach((slide: any) => {
      if (slide && slide?.judgementItems) {
        for (const langKey of Object.keys(slide?.judgementItems?.situationPhrase || {})) {
          if (slide?.judgementItems?.situationPhrase[langKey]?.audioName === '') {
            delete slide?.judgementItems?.situationPhrase[langKey]?.audioName;
          }
          delete slide?.judgementItems?.situationPhrase[langKey].audioString;
          delete slide?.judgementItems?.situationPhrase[langKey].isChanged;
        }

        slide.judgementItems?.situationDetailItems.forEach((ele: any) => {
          ele.phrases.forEach((phraseEle: any) => {
            for (const langKey of Object.keys(phraseEle.phrase || {})) {
              if (phraseEle.phrase[langKey]?.audioName === '') {
                delete phraseEle.phrase[langKey]?.audioName;
              }
              delete phraseEle.phrase[langKey].audioString;
              delete phraseEle.phrase[langKey].isChanged;
            }
          });
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.RecallDailyTasks) {
    activity?.metadata?.slides?.forEach((slide: any) => {
      if (slide && slide?.recallDailyTasks) {
        // For sender name(audio)
        removeAudioPhrasesProperties(slide?.recallDailyTasks?.sender?.phrases);

        // For sender image
        removeAudioMediaProperties(slide.recallDailyTasks.sender?.media);

        // For Note Section
        removeAudioPhrasesProperties(slide.recallDailyTasks.note.phrases);

        //  For Text Message Section
        removeAudioPhrasesProperties(slide.recallDailyTasks.textMessage.phrases);

        // For Voice Message Section
        removeAudioPhrasesProperties(slide.recallDailyTasks.voiceMessage.phrases);

        // For Phone Message Section
        removeAudioPhrasesProperties(slide.recallDailyTasks.phoneMessage.phrases);

        // For Question section
        removeAudioPhrasesProperties(slide.recallDailyTasks.question.phrases);

        // For Answers Section
        slide?.recallDailyTasks?.answers?.items?.forEach((answerItem: any) => {
          removeAudioPhrasesProperties(answerItem?.phrases);
        });
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.AssemblingWords) {
    activity?.metadata?.slides?.forEach((slide: any) => {
      if (slide && slide?.assemblingWords) {
        // For words phrases
        removeAudioPhrasesProperties(slide?.assemblingWords?.words?.phrases);

        // For words media
        removeAudioMediaProperties(slide.assemblingWords.words?.media);
      }
    });
  }

  if (activity?.type?.name === ActivityTypes.Anagram) {
    activity?.metadata?.slides?.forEach((slide: any) => {
      if (slide && slide?.anagram) {
        // For words phrases
        removeAudioPhrasesProperties(slide?.anagram?.words?.phrases);
      }
    });
  }

  return true;
}

// INFO: Helper function for removing extra details from phrases for removeAudioExtraDetails function
const removeAudioPhrasesProperties = (phrases) => {
  for (const langKey of Object.keys(phrases || {})) {
    if (phrases[langKey]?.audioName === '') {
      delete phrases[langKey]?.audioName;
    }
    delete phrases[langKey]?.audioString;
    delete phrases[langKey]?.isChanged;
  }
};

// INFO: Helper function for removing extra details from media for removeAudioExtraDetails function
const removeAudioMediaProperties = (mediaArray) => {
  for (const [index, media] of mediaArray.entries()) {
    if (media?.audioName === '') {
      delete media.audioName;
    }
    delete media.audioString;
    delete media.isChanged;
  }
};

export function validateImages(activity, articulationActivity = false) {
  let metadataToPrepare = [];
  if (activity) metadataToPrepare = activity?.metadata?.slides;
  if (articulationActivity) metadataToPrepare = flatArticulationMetadata(activity);

  for (const slide of metadataToPrepare) {
    if (slide?.media && slide?.media.length) {
      for (const media of slide?.media) {
        if (media.valid != null && !media.valid) {
          return false;
        }
      }
    }
    if (activity?.type?.name === ActivityTypes.RecallDailyTasks) {
      if (slide?.recallDailyTasks) {
        for (const media of slide.recallDailyTasks.sender?.media) {
          if (media.valid != null && !media.valid) {
            return false;
          }
        }
      }
    }

    if (activity?.type?.name === ActivityTypes.AssemblingWords) {
      if (slide?.assemblingWords) {
        for (const media of slide.assemblingWords.words?.media) {
          if (media.valid != null && !media.valid) {
            return false;
          }
        }
      }
    }
  }

  return true;
}

export async function getActivity(id) {
  const getActivityApi = usePromise((id) => findActivityOne(id), { lazy: true });
  return (await getActivityApi.exec(id)).data;
}

export async function getArticulation(id) {
  const getArticulationApi = usePromise((id) => findArticulationOne(id), { lazy: true });
  return (await getArticulationApi.exec(id)).data;
}

export function getMultilanguageFieldText(value) {
  if (ALLOWED_LANGUAGES.en in value && value[ALLOWED_LANGUAGES.en]) {
    return value[ALLOWED_LANGUAGES.en];
  }
  if (ALLOWED_LANGUAGES.he in value && value[ALLOWED_LANGUAGES.he]) {
    return value[ALLOWED_LANGUAGES.he];
  }
  if (ALLOWED_LANGUAGES.ar in value && value[ALLOWED_LANGUAGES.ar]) {
    return value[ALLOWED_LANGUAGES.ar];
  }
  if (ALLOWED_LANGUAGES.de in value && value[ALLOWED_LANGUAGES.de]) {
    return value[ALLOWED_LANGUAGES.de];
  }
  if (ALLOWED_LANGUAGES.es in value && value[ALLOWED_LANGUAGES.es]) {
    return value[ALLOWED_LANGUAGES.es];
  }
  if (ALLOWED_LANGUAGES.be in value && value[ALLOWED_LANGUAGES.be]) {
    return value[ALLOWED_LANGUAGES.be];
  }
  if (ALLOWED_LANGUAGES.ru in value && value[ALLOWED_LANGUAGES.ru]) {
    return value[ALLOWED_LANGUAGES.ru];
  }
  if (ALLOWED_LANGUAGES.tr in value && value[ALLOWED_LANGUAGES.tr]) {
    return value[ALLOWED_LANGUAGES.tr];
  }
}

export function getChangedObj(oldObj: object, newObj: object) {
  const changedObj = {};
  Object.keys(newObj).forEach((key) => {
    if (oldObj[key] != newObj?.[key] && newObj?.[key] != null) {
      changedObj[key] = newObj[key];
    }
  });
  return changedObj;
}

export function getActivityAudioPath(name: string, activity: any) {
  if (!name) {
    return null;
  }
  const urlPath = `${environment.cdn}/${activity.type.domain.name.toLowerCase()}/${activity.type.name.toLowerCase()}/${
    activity._id
  }/__FILE__`;
  return getAudioPath(name, urlPath);
}

export function getAudioPath(name: string, urlPrefix: string) {
  if (!name) return null;
  if (name.includes('.mp3')) name = name.replace('.mp3', '');
  return urlPrefix.replace('__FILE__', 'content/audio/' + dasherize(name.replace(/\s/g, '-')) + '.mp3' + forceUpdate());
}

export const forceUpdate = () => {
  return `?GUID=${moment().valueOf()}`;
};

export function getErrorMessage(apiResponse) {
  let errorMsg = '';
  apiResponse.map((errMessage) => {
    errorMsg = errorMsg + '<p>' + `${errMessage} ` + '</p>';
  });
  return errorMsg;
}

export const isImageBasedActivity = (mediaType) => {
  return [ACTIVITY_ENUM.mediaType.photo, ACTIVITY_ENUM.mediaType.isolatePhoto, ACTIVITY_ENUM.mediaType.illustration].includes(mediaType);
};

export const checkNameRecallPhaseValidation = (activity) => {
  const slides = activity?.metadata?.slides;
  const slidesExcludingSummary = slides?.slice(0, activity?.metadata?.slides?.length - 1);

  if (activity?.metadata?.isAlternateTextOrAudio && isImageBasedActivity(activity.mediaType)) {
    return slides.length
      ? !slidesExcludingSummary?.some(
          (el) =>
            Object.keys(el?.phrases).sort().join(',') !== activity.languageCode.sort().join(',') ||
            Object.keys(el?.alternatePhrases).sort().join(',') !== activity.languageCode.sort().join(',')
        ) &&
          !slidesExcludingSummary?.some(
            (el) =>
              Object.values(el?.phrases).includes('') ||
              Object.values(el?.phrases).includes(null) ||
              Object.values(el?.alternatePhrases).includes(null) ||
              Object.values(el?.alternatePhrases).includes('')
          )
      : false;
  }
  if (activity?.metadata?.isAlternateTextOrAudio && activity.mediaType === ACTIVITY_ENUM.mediaType.video) {
    return slides.length
      ? !slidesExcludingSummary?.some(
          (el) => Object.keys(el?.alternatePhrases).sort().join(',') !== activity.languageCode.sort().join(',')
        ) &&
          !activity?.metadata?.slides
            ?.slice(0, activity?.metadata?.slides?.length - 1)
            .some((el) => Object.values(el?.alternatePhrases).includes('') || Object.values(el?.alternatePhrases).includes(null))
      : false;
  }
  if (!activity?.metadata?.isAlternateTextOrAudio && isImageBasedActivity(activity.mediaType)) {
    return slides.length
      ? !slidesExcludingSummary?.some((el) => Object.keys(el?.phrases).sort().join(',') !== activity.languageCode.sort().join(',')) &&
          !slidesExcludingSummary?.some((el) => Object.values(el?.phrases).includes('') || Object.values(el?.phrases).includes(null))
      : false;
  }

  return true;
};

export const checkNameRecallSlideMediaValidation = (activity) => {
  return activity?.metadata?.slides.length
    ? !activity?.metadata?.slides
        ?.slice(0, activity?.metadata?.slides?.length - 1)
        .some((el) => !el?.media?.length || el?.media?.length > 1)
    : false;
};

export const checkNameRecallSummarySlideValidation = (activity) => {
  const mediaPerLevel = {
    1: 2,
    2: 2,
    3: 5,
    4: 5,
    5: 12,
    6: 12,
    7: 10,
    8: 10,
    9: 15,
    10: 15,
  };
  return {
    status:
      !mediaPerLevel[Number(activity.level)] || !activity?.metadata?.slides[activity?.metadata?.slides?.length - 1]?.media?.length
        ? false
        : mediaPerLevel[Number(activity.level)] === activity?.metadata?.slides[activity?.metadata?.slides?.length - 1]?.media?.length,
    numberOfImages: !mediaPerLevel[Number(activity.level)] ? '' : mediaPerLevel[Number(activity.level)],
  };
};

export async function getAllowedLanguage() {
  const { user: currentUser } = useState('auth', ['user']);
  const getRoles = Role.all();

  const userLanguage = [];
  getRoles.forEach((role: any) => {
    if (role.name === Roles.COGNISHINE_CONTRIBUTOR || role.name === Roles.ADMIN_TEXT_EDITOR) {
      currentUser.value.roles.forEach((userRole) => {
        if (userRole.id === role._id && userRole.allowedLanguages && userRole.allowedLanguages.length > 0) {
          userRole.allowedLanguages.forEach((language) => {
            if (!userLanguage.includes(language)) {
              userLanguage.push(language);
            }
          });
        }
      });
    }
  });
  return userLanguage;
}

export const getAvailableLanguages = () => {
  const languages = [];
  LanguagesAlongWithWebsite.query()
    .newQuery()
    .withAllRecursive()
    .all()
    .map((el) => languages.push((el as any).languageCode));

  return languages;
};

const AVAILABLE_ACTIVITY_FOR_AUDIO_KEY_CHANGE = [
  {
    type: ActivityTypes.Comprehension,
    isMultiple: false,
  },
  {
    type: ActivityTypes.IdentifyingEmotions,
    isMultiple: false,
  },
  {
    type: ActivityTypes.NameRecall,
    isMultiple: false,
  },
  {
    type: ActivityTypes.AuditoryComprehensionWord,
    isMultiple: false,
  },
  {
    type: ActivityTypes.AuditoryComprehensionSentence,
    isMultiple: false,
  },
  {
    type: ActivityTypes.AuditoryComprehensionPhrase,
    isMultiple: false,
  },
  {
    type: ActivityTypes.Naming,
    isMultiple: true,
  },
];
export const needToChangeAudioField = (currentActivityType) => {
  return !!AVAILABLE_ACTIVITY_FOR_AUDIO_KEY_CHANGE.find((activityType) => activityType.type === currentActivityType);
};

export const isAvailableForMultipleUpload = (currentActivityType) => {
  return !!AVAILABLE_ACTIVITY_FOR_AUDIO_KEY_CHANGE.find(
    (activityType) => activityType.type === currentActivityType && activityType.isMultiple
  );
};

export const checkIsSelectedTargetIsBin = (val, activity) => {
  return activity.metadata.slides.find((slide) => {
    if (slide?.activityPlaningColumnType === ACTIVITY_PLANNING_COLUMN_TYPE.bin) {
      return Object.entries(slide.phrases[0]).find(([key, value]) => {
        if (val) {
          return value === val;
        }
      });
    }
  });
};

export const deleteNotToStoreDataFromNewAudioObj = (audioObj) => {
  const obj = JSON.parse(JSON.stringify(audioObj));

  delete obj.audioString;
  delete obj.isChanged;
  return obj;
};

export const getSlideCountError = (activityType) => {
  const activityErrors = {
    [ActivityTypes.StorySequence]: `${ACTIVITY_ENUM?.errorType?.slideCount} ${STORY_SEQUENCE_ACTIVITY_CONFIG?.slideCount?.minSlideCount} to ${STORY_SEQUENCE_ACTIVITY_CONFIG?.slideCount?.maxSlideCount}.`,
  };
  return activityErrors[activityType];
};

//TODO: remove this function
//INFO: This function is used for temporary solution to disable auto logout for given list of developers
export const isRegisterAutoLogoutListner = (email) => {
  const allowedEmails = [
    'milan.rabara@cognishine.com',
    'ido@cognishine.com',
    'gennady.treibich@cognishine.com',
    'parshv.s@cognishine.com',
    'alfina.mansuri@cognishine.com',
    'sarang.tandel@cognishine.com',
  ];

  return !allowedEmails.includes(email);
};

export const isActivitySlideCountInvalid = (slideLength) => {
  return slideLength >= STORY_SEQUENCE_ACTIVITY_CONFIG.slideCount.minSlideCount &&
    slideLength <= STORY_SEQUENCE_ACTIVITY_CONFIG.slideCount.maxSlideCount
    ? false
    : true;
};

export const getDefaultPhrasesObject = () => {
  return { text: '', audioName: '', audioString: '', isChanged: false };
};

export const getDefaultAudioDetailsObject = () => {
  return { generationType: AUDIO_MODE.auto, audioSettings: {} };
};

// INFO: Helper function to manage phrases, audio details, and media (conditionally) based on selected languages.
export const updatePhrasesAndAudioDetails = (obj, languageCodes, mediaPerLanguage = false) => {
  // INFO: Helper function to synchronize phrases, audioDetails, and media (conditionally)
  const synchronizeLanguageEntries = (obj) => {
    const { phrases, audioDetails, media } = obj;

    // INFO: Handle phrases and audioDetails (if present)
    if (phrases && audioDetails) {
      Object.keys(phrases)?.forEach((key) => {
        if (!languageCodes.includes(key)) {
          delete phrases[key];
          delete audioDetails[key];
        }
      });

      languageCodes?.forEach((lang) => {
        if (!phrases[lang]) {
          phrases[lang] = { text: null, audioName: null, audioString: null, isChanged: false };
          audioDetails[lang] = { generationType: AUDIO_MODE.auto, audioSettings: {} };
        }
      });
    }

    // INFO: Handle media (if mediaPerLanguage is true)
    if (mediaPerLanguage && media) {
      Object.keys(media)?.forEach((key) => {
        if (!languageCodes.includes(key)) {
          delete media[key];
        }
      });

      languageCodes?.forEach((lang) => {
        if (!media[lang]) {
          media[lang] = [{ audioName: null, audioString: null, isChanged: false }];
        }
      });
    }
  };

  // Recursive function to apply updates to nested objects
  const applyUpdatesRecursively = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((item) => applyUpdatesRecursively(item));
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj = obj;

      if (('phrases' in newObj && 'audioDetails' in newObj) || ('media' in newObj && mediaPerLanguage)) {
        synchronizeLanguageEntries(newObj);
      }

      Object.keys(newObj).forEach((key) => {
        newObj[key] = applyUpdatesRecursively(newObj[key]);
      });

      return newObj;
    }

    return obj;
  };

  return applyUpdatesRecursively(obj);
};

// INFO: Helper function for generating default item conditionally for phrases and audio details
export const generateDefaultItem = (languageCodes: string[], isLanguageContext: boolean, isAudioNoLangContext: boolean, isMedia = true) => {
  if (isLanguageContext) {
    const phrases = languageCodes.reduce((acc, lang) => {
      acc[lang] = { text: null, audioName: null, audioString: null, isChanged: false };
      return acc;
    }, {} as Record<string, { text: string | null; audioName: string | null; audioString: string | null; isChanged: boolean }>);

    const audioDetails = languageCodes.reduce((acc, lang) => {
      acc[lang] = { generationType: AUDIO_MODE.auto, audioSettings: {} as Record<string, any> }; // Use Record<string, any> for flexible settings
      return acc;
    }, {} as Record<string, { generationType: string; audioSettings: Record<string, any> }>);

    return {
      ...(isMedia && { media: isAudioNoLangContext ? [{ audioName: null }] : [] }),
      phrases,
      audioDetails,
    };
  } else {
    return {
      ...(isMedia && { media: isAudioNoLangContext ? [{ audioName: null }] : [] }),
      phrases: { [ALLOWED_LANGUAGES.en]: { text: null, audioName: null, audioString: null, isChanged: false } },
      audioDetails: { [ALLOWED_LANGUAGES.en]: { generationType: AUDIO_MODE.auto, audioSettings: {} as Record<string, any> } }, // Use Record<string, any>
    };
  }
};

export const isBase64String = (base64String) => {
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  // Remove the MIME type part from the string
  const base64Data = base64String?.split(',')[1];

  return base64Regex.test(base64Data);
};
