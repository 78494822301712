import Joi from 'joi';
import mongoose, { Document, Schema } from 'mongoose';
import { AUDIO_MODE } from './activity';

export enum activityCardOptions {
  FLASH_CARD = 'FLASH_CARDS',
  MEMORY_PAIR = 'MEMORY_PAIRS',
}

export enum cardOptions {
  largeText = 'LARGE_TEXT',
  imageOnly = 'IMAGE_ONLY',
  textOnly = 'TEXT_ONLY',
  largeImage = 'LARGE_IMAGE',
  darkMode = 'DARK_MODE',
  imageAndText = 'IMAGE_AND_TEXT',
}

export enum studioFilterTypes {
  basic = 0,
  advanced = 1,
}

export const PHONETICS_WORDS = {
  visiblity: {
    public_activity: 0,
    protected_activity: 1,
    private_organization_activity: 2,
  },
  audioStatus: ['waitingForCustomeAudio', 'none'],
  innerStatus: [
    'Started',
    'WaitingForImages',
    'WaitingForReview',
    'Rejected',
    'Reviewed',
    'Ready',
  ],
  partOfSpeech: [
    'noun',
    'verb',
    'adjective',
    'adverb',
    'preposition',
    'pronoun',
    'conjunction',
    'interjection',
  ],
  errorType: {
    limitError: 'Not allowed to add more breakpoint.',
    image: 'Image is required.',
    word: 'Word is required.',
    validationError: 'Can not save the record. Please see the errors below.',
    serverError: 'Can not save the record. Please try again later.',
    updatedTimestampError:
      'Record was updated by another user. Please refresh the page and try again.',
    audio: 'Audio is required.',
    syllable: 'Syllables are required.',
    structure: 'Structure is required.',
    ipaSound: 'One or more sounds are not assigned.',
    punctuation: 'Punctuation is required.',
    imageName:
      'Image name must include at least 8 digits (e.g. istock_437634874747.jpg)',
  },
  breakDownType: {
    strong: 'Strong',
    weak: 'Weak',
  },
  category: [
    'Fruits',
    'Vegetables',
    'Furniture',
    'Transportation vehicles',
    'Tools',
    'Musical instruments',
    'Cleaning tools',
    'Kitchenware',
    'Writing instruments',
    'Clothing',
    'Electrical products',
    'Professions',
    'Food',
    'Animals',
    'Body parts',
    'Jewelry and Makeup',
    'Seasons & Weather',
    'Holidays',
    'Places',
    'Emotions',
    'Accessories',
    'Sport',
    'Music',
  ],
  prevalenceOptions: ['Low', 'Moderate', 'High'],
  relatedPositionField: {
    initial: 'initialSound',
    medial: 'medialSound',
    final: 'finalSound',
  },
  syllableCount: [1, 2, 3, 4, 5, 6, 7, 8],
  genderOptions: [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
  ],
  singularOrPluralOptions: [
    { value: 'singular', label: 'Singular' },
    { value: 'plural', label: 'Plural' },
  ],
  imageType: {
    standard: 'imagePath',
    isolated: 'isolateImagePath',
    illustration: 'illustrationImagePath',
  },

  selectedContentLimit: 50,
  sortWordsOptions: [
    { value: 'alphabet', label: 'Alphabet', sortField: 'text' },
    { value: 'length', label: 'Length', sortField: 'textLength' },
    { value: 'syllables', label: 'Syllables', sortField: 'syllableCount' },
    { value: 'random', label: 'Random', sortField: 'randomSort' },
  ],
  soundOccurrence: ['SINGLE', 'MULTIPLE'],
  contentType: {
    word: 'WORD',
    phrase: 'PHRASE',
    sentence: 'SENTENCE',
  },
  blendOptions: {
    includeBLends: 'INCLUDE_BLENDS',
    excludeBlends: 'EXCLUDE_BLENDS',
    blendsOnly: 'BLENDS_ONLY',
  },

  advanceSearchOptions: {
    soundPositions: {
      startsWith: 'STARTS_WITH',
      endsWith: 'ENDS_WITH',
      containsWith: 'CONTAINS_WITH',
    },
  },
};

export const ORG_RECORDS_TYPE = {
  allRecords: 'allRecords',
  myOrgRecords: 'myOrgRecords',
};
export interface PhoneticsWords {
  text: string;
}

export interface MongoosePhoneticsWords extends PhoneticsWords, Document {}

const PhoneticsWordsSchema = new Schema(
  {
    language: { type: Schema.Types.ObjectId, ref: 'Language', index: true },
    published: { type: Boolean, default: false, index: true },
    visibility: {
      visibilityType: {
        type: Number,
        default: 0,
        enum: Object.values(PHONETICS_WORDS.visiblity),
      },
      visibilityToOrg: {
        type: [Schema.Types.ObjectId],
        default: [],
        ref: 'Organization',
      },
    },
    targetWebsite: {
      type: [
        {
          website: { type: Schema.Types.ObjectId, ref: 'Website' },
          lastPublishedDate: { type: Date, default: null },
          prevalence: {
            type: String,
            default: PHONETICS_WORDS.prevalenceOptions[1],
            enum: PHONETICS_WORDS.prevalenceOptions,
          },
        },
      ],
      default: [],
    },
    text: { type: String, default: null },
    punctuation: { type: String, default: null },
    partOfSpeech: {
      type: String,
      default: null,
      enum: [...PHONETICS_WORDS.partOfSpeech, null],
    },
    structure: {
      type: Schema.Types.ObjectId,
      ref: 'Phonetics-Structure',
      default: null,
      index: true,
    },
    syllableCount: { type: Number, default: null, index: true },

    // syllableBreakdownRepresentation: {
    //   type: [
    //     {
    //       syllableType: {
    //         type: String,
    //         default: null,
    //         enum: Object.keys(PHONETICS_WORDS.breakDownType),
    //       },
    //       group: { type: [Schema.Types.ObjectId], default: [] },
    //     },
    //   ],
    //   default: [],
    // },

    // Note : We can change syllable field in later as per our needs.
    // syllable: [
    //   {
    //     startIndex: { type: Number, default: null },
    //     count: { type: Number, default: null },
    //     type: {
    //       type: String,
    //       default: null,
    //       enum: Object.keys(PHONETICS_WORDS.breakDownType),
    //     },
    //   },
    // ],
    syllable: {
      type: {
        syllableBreakdownIndexes: [{ type: Number }],
        syllableBreakdownStrength: [{ type: String }],
        blendsCombination: {
          type: [[{ type: Schema.Types.ObjectId, ref: 'Phonetics-Sounds' }]],
          default: [],
        },
      },
      default: null,
    },
    phoemeCount: {
      type: Number,
      default: null,
    },
    anatomy: {
      type: [
        {
          phoneme: { type: Schema.Types.ObjectId, ref: 'Phonetics-Sounds' },
          text: { type: String, default: '' },
        },
      ],
      default: [],
    },
    initialSound: {
      type: Schema.Types.ObjectId,
      ref: 'Phonetics-Sounds',
      default: null,
      index: true,
    },
    finalSound: {
      type: Schema.Types.ObjectId,
      ref: 'Phonetics-Sounds',
      default: null,
      index: true,
    },
    medialSound: {
      type: [{ type: Schema.Types.ObjectId, ref: 'Phonetics-Sounds' }],
      default: [],
      index: true,
    },
    allSound: {
      type: [{ type: Schema.Types.ObjectId, ref: 'Phonetics-Sounds' }],
      default: [],
      index: true,
    },
    imagePath: { type: String, default: null },
    isolateImagePath: { type: String, default: null },
    illustrationImagePath: { type: String, default: null },
    audio: {
      type: {
        audioType: {
          type: String,
          default: null,
          enum: Object.values(AUDIO_MODE),
        },
        path: { type: String, default: null },
        waitingForAudio: {
          type: Boolean,
          default: false,
        },
        audioSettings: { type: Object, default: {} },
      },
    },
    createdBy: { type: Schema.Types.ObjectId, ref: 'User' },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
    ownedBy: { type: Schema.Types.ObjectId, ref: 'Organization' },
    innerStatus: {
      type: String,
      default: PHONETICS_WORDS.innerStatus[0],
      enum: PHONETICS_WORDS.innerStatus,
    },
    comment: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
      enum: [...PHONETICS_WORDS.category, null],
    },
    wordGenderType: {
      type: String,
      default: null,
      enum: [null, ...PHONETICS_WORDS.genderOptions.map((el) => el.value)],
    },
    wordSingularOrPluralType: {
      type: String,
      default: null,
      enum: [
        null,
        ...PHONETICS_WORDS.singularOrPluralOptions.map((el) => el.value),
      ],
    },
    tags: { type: [String], default: [] },
    randomSort: { type: Number, default: null },
    isAiGenerated: { type: Boolean, default: false },
  },
  { timestamps: { createdAt: 'created_at', updatedAt: 'updated_at' } }
);

PhoneticsWordsSchema.methods.joiValidate = (obj) => {
  const schema = Joi.object({
    text: Joi.string().required(),
  }).unknown();
  return schema.validate(obj, { abortEarly: false });
};

export const PhoneticsWords = mongoose.model<MongoosePhoneticsWords>(
  'Phonetics-Words',
  PhoneticsWordsSchema
) as any;
