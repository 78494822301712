import axios from "axios";

const {ZAPIER_WEBHOOK_URL, ENV} = process.env;

export async function handleUserDataDispatch(userData, lastPurchase = null) {
  const user = userData as any; //MongooseUser
  try {
    if (ENV == 'prod') {
      if (user.isNew) {
        const userData = await user.populate('organization').populate('purchase').execPopulate();
        await dispatchUserData(userData, "newUser");
      }
      if (user.isModified() && !user.isNew &&
        (user.isModified("organization") || user.isModified("purchase") ||
          user.isModified("preferences.newsletters") || user.isModified("name") || user.isModified("isPaying") ||
          user.isModified("externalPaymentPlan") || user.isModified("email")
        )
      ) {
        const userData = await user.populate('organization').populate('purchase').execPopulate();
        await dispatchUserData(userData, "updateUser", lastPurchase);
      }
    }

  } catch (error) {
    console.error("Error in pre-save hook (dispatch user data):", error);
  }
}

async function dispatchUserData(userData, actionType, lastPurchaseData = null) {
  const lastPurchase = lastPurchaseData || userData?.purchase?.purchases?.slice(-1)[0]
  const webhookData = {
    actionType: actionType,
    email: userData.email,
    name: userData.name,
    firstName: userData.name.split(" ")[0],
    lastName: userData.name.split(" ")?.[1],
    isPaying: userData?.isPaying || false,
    newsletters: userData?.preferences?.newsletters || true,
    externalPaymentPlan: userData?.externalPaymentPlan || false,
    organization: userData?.organization?.name,
    createdAt: userData?.created_at,
    planAutoRenew: lastPurchase?.autoRenew,
    purchaseDate: lastPurchase?.purchaseDate,
    purchaseCurrency: lastPurchase?.currency,
    cycleChargeAmount: lastPurchase?.cycleChargeAmount,
    subscriptionStartDate: lastPurchase?.subscriptionStartDate,
    planType: lastPurchase?.plan?.type,
    initialNextBillingTime: lastPurchase?.initialNextBillingTime,
    freeTrialRedeemDate: userData?.freeTrialRedeemDate,
    verified: userData?.verified,
    creationSource: userData?.creationSource,
  }
  await axios.post(ZAPIER_WEBHOOK_URL, webhookData)
}
